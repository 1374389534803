import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { AmplifySignOut } from '@aws-amplify/ui-react';

import { Auth } from 'aws-amplify';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css'

function Home() {

	const navigate = useNavigate();

	return (
		<Container>
			<Row className="TitleBar">
				<Col>
					<Button onClick={() => {
						navigate('../')
					}}>
						Home
					</Button>
				</Col>
				<Col>
					Skware Wishlist
				</Col>
				<Col>
					<AmplifySignOut />
				</Col>
			</Row>
			<Row className="Section" onClick={() => {
				navigate("../groups")
			}}>
				<Col>
					<div className="HorizontalCell">
						My Groups
					</div>
				</Col>
			</Row>
			<Row className="Section" onClick={() => {
				Auth.currentUserInfo().then(user => {
					console.log(user)
					navigate(`../wishlist?id=${user.username}`)
				})
			}}>
				<Col>
					<div className="HorizontalCell">
						My Wishlist
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default Home;