import {
	Button,
	Col,
	Container,
	Form,
	Modal,
	ModalBody,
	ModalTitle,
	Row
} from "react-bootstrap";

import {
	useState,
	useEffect
} from "react";

import { useNavigate } from "react-router";
import { AmplifySignOut } from '@aws-amplify/ui-react';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import ModalHeader from "react-bootstrap/esm/ModalHeader";

import {
	createGroup,
	getGroups
} from './Api.js';

function Groups() {

	const [newGroup, setNewGroup] = useState(false)
	const [groupName, setGroupName] = useState("")
	const [groups, setGroups] = useState([])
	const [pending, setPending] = useState(true)
	const navigate = useNavigate();

	const submitCreateGroup = () => {
		createGroup(groupName).then(response => {
			navigate(`../group?id=${response.groupId}`)
		})
	}

	useEffect(() => {
		getGroups().then(response => {
			setGroups(response.results)
			setPending(false)
		})
	}, [])

	const Group = ({ group }) => {
		return (
			<div
				className="HorizontalCell"
				onClick={() => {
					navigate(`../group?id=${group.GroupId}`)
				}}
			>
				<div>
					{group.GroupName}
				</div>
			</div>
		)
	}

	return (
		<>
			<Container>
				<Row className="TitleBar">
					<Col>
						<Button onClick={() => {
							navigate('../')
						}}>
							Home
						</Button>
					</Col>
					<Col>
						Your Groups
					</Col>
					<Col>
						<AmplifySignOut />
					</Col>
				</Row>
				<Row className="Section">
					<Col>
						<Button className="shadow-none" onClick={() => {
							setNewGroup(true)
						}}>
							Create Group
						</Button>
					</Col>
				</Row>
				<Row className="Section">
					<Col>
						{groups.map(thisGroup => <Group group={thisGroup} />)}
					</Col>
				</Row>
			</Container>
			<Modal
				show={newGroup}
				onHide={() => {
					setNewGroup(false)
					setGroupName("")
				}}
			>
				<ModalHeader>
					<ModalTitle>
						Create a Group
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Form.Group>
						<Form.Label>Group Name</Form.Label>
						<Form.Control placeholder="Enter group name" onChange={e => setGroupName(e.target.value)} />
					</Form.Group>
					<br />
					<Button className="shadow-none" onClick={submitCreateGroup}>
						Submit
					</Button>
				</ModalBody>
			</Modal>
		</>
	);
}

export default Groups;