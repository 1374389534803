import { Auth } from 'aws-amplify';

const authApiTemplate = (method, payload) => {
	return new Promise(resolve => {

		Auth.currentSession().then(res => {
			let idToken = res.getIdToken().getJwtToken()

			// Setting the timeout limit
			let timeoutSecondLimit = 10;
			var timedOut = false;
			const timeoutId = setTimeout(() => {
				timedOut = true;
				resolve({ success: false, message: "TimeOut" })
			}, timeoutSecondLimit * 1000);

			console.log(JSON.stringify(JSON.stringify(payload)))

			fetch(
				` https://f3ry2kd2d9.execute-api.us-east-2.amazonaws.com/prod/${method}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': idToken
					},
					body: JSON.stringify(payload)
				}
			).then(response =>
				response.json()
			).then(response => {
				clearTimeout(timeoutId);
				if (timedOut) return;
				resolve({ success: true, ...response });
			}).catch(err => {
				clearTimeout(timeoutId);
				if (timedOut) return;
				resolve({ success: false, ...err });
			})

		}).catch(err => {
			resolve({
				success: false,
				...err
			})
		})
	})
}

// export const getCards = (setId) => {
// 	return apiTemplate('getcards', {
// 		setId
// 	})
// }

export const createGroup = (groupName) => {
	return authApiTemplate('creategroup', {
		groupName
	})
}

export const getGroups = () => {
	return authApiTemplate('getgroups', {})
}

export const getGroup = (groupId) => {
	return authApiTemplate('getgroup', {
		groupId
	})
}

export const addParticipant = (groupId, username) => {
	return authApiTemplate('addparticipant', {
		groupId,
		username
	})
}

export const getWishlist = (username) => {
	return authApiTemplate('getwishlist', {
		username
	})
}

export const createWish = (username, title, wishUrl, note, groupId) => {
	return authApiTemplate('createwish', {
		username,
		title,
		wishUrl,
		note,
    groupId
	})
}

export const deleteWish = (username, wishId) => {
	return authApiTemplate('deletewish', {
		username,
		wishId
	})
}

export const toggleGetting = (WishId, SuggestionId) => {
	return authApiTemplate('togglegetting', {
		WishId,
		SuggestionId
	})
}