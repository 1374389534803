import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css'

import {
	BrowserRouter,
	Routes,
	Route
} from "react-router-dom";

import Home from './Home';
import Groups from './Groups';
import Group from './Group';
import Wishlist from './Wishlist';

import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';

function App() {
	const [user, updateUser] = React.useState(null);
	React.useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then(user => updateUser(user))
			.catch(() => console.log('No signed in user.'));
		Hub.listen('auth', data => {
			switch (data.payload.event) {
				case 'signIn':
					return updateUser(data.payload.data);
				case 'signOut':
					return updateUser(null);
			}
		});
	}, [])
	if (user) {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/groups" element={<Groups />} />
					<Route path="/group" element={<Group />} />
					<Route path="/wishlist" element={<Wishlist />} />
				</Routes>
			</BrowserRouter>
		)
	}
	console.log("Anauthenticated")
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<AmplifyAuthenticator>
				<AmplifySignUp
					slot="sign-up"
					formFields={[
						{ type: "username" },
						{
							type: "password",
							label: "Password *",
							placeholder: "Enter a password"
						},
						{ type: "email" }
					]}
				/>
			</AmplifyAuthenticator>
		</div>
	);
}

export default App;