import {
	Button,
	Col,
	Container,
	Form,
	FormCheck,
	Modal,
	ModalBody,
	ModalTitle,
	Row
} from "react-bootstrap";

import {
	useState,
	useEffect
} from "react";

import { useNavigate } from "react-router";
import { AmplifySignOut } from '@aws-amplify/ui-react';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import { Auth } from 'aws-amplify';
import ModalHeader from "react-bootstrap/esm/ModalHeader";

import {
	getWishlist,
	createWish,
	deleteWish,
	toggleGetting
} from './Api.js';

function Groups() {

	const [addWish, setAddWish] = useState(false);
	const [wishTitle, setWishTitle] = useState("");
	const [wishUrl, setWishUrl] = useState("");
	const [wishNote, setWishNote] = useState("");
	const [wishes, setWishes] = useState([]);
	const [activeUserId, setActiveUserId] = useState();

	const [isDeleting, setIsDeleting] = useState(false);
	const [deletionTarget, setDeletionTarget] = useState();
	const [deletionTitle, setDeletionTitle] = useState();

	let search = window.location.search;
	let params = new URLSearchParams(search);
	const navigate = useNavigate();

	const submitDelete = () => {
		// TODO
		deleteWish(params.get('id'), deletionTarget).then(response => {
			let newWishes = wishes.filter(wish => wish.WishId !== deletionTarget && wish.SuggestionId !== deletionTarget)
			setWishes(newWishes)
			setIsDeleting(false)
			setDeletionTarget(undefined)
			setDeletionTitle(undefined)
		})
	}

	const cancelDelete = () => {
		setIsDeleting(false)
		setDeletionTarget(undefined)
		setDeletionTitle(undefined)
	}

	const submitAddWish = () => {
		const valid = wishUrl.startsWith('https://') || wishUrl.startsWith('http://') || wishUrl.length===0
		if (valid) {
			createWish(params.get('id'), wishTitle, wishUrl, wishNote).then(response => {
				if (response.success) {
					setWishes([...wishes, {
						Title: wishTitle,
						WishId: response.wishId,
						WishUrl: wishUrl,
						Note: wishNote,
						WisherId: activeUserId,
						GetterId: undefined
					}])
					setWishTitle("")
					setWishUrl("")
					setWishNote("")
					setAddWish(false)
				}
			})
		} else {
			alert("Please start your url with http:// or https://")
		}
	}

	useEffect(() => {
		Auth.currentUserInfo().then(user => {
			setActiveUserId(user.username)
		})
		getWishlist(params.get('id')).then(response => {
			console.log(response)
			setWishes(response.wishes || [])
		})
	}, [])

	const Wish = ({ wish }) => {
		return (
			<div
				className="HorizontalCell"
				onClick={() => {
					// TODO
				}}
			>
				<div className="HorizontalCellHeader">
					<span>{wish.RecommenderId ? `${wish.RecommenderId}'s Suggestion` : `${params.get('id')}'s Wish'` }: {wish.Title}</span>
					{
						((activeUserId === wish.WisherId || activeUserId === wish.RecommenderId) ?
							<Button onClick={() => {
								setIsDeleting(true)
								setDeletionTarget(wish.WishId || wish.SuggestionId)
								setDeletionTitle(wish.Title)
							}}>
								Delete
							</Button>
							:
							<></>
						)
					}
				</div>
				<div>Url: <a href={wish.WishUrl || wish.SuggestionUrl} target="_blank">{wish.WishUrl || wish.SuggestionUrl}</a></div>
				<div>Note: {wish.Note}</div>
				{
					(params.get('id') !== activeUserId) ?
						<div>
							<FormCheck
								disabled={wish.GetterId != null && wish.GetterId != activeUserId}
								type='checkbox'
								inline
								checked={wish.GetterId != null}
								onChange={() => {
									if (wish.GetterId == null) {
										toggleGetting(wish.WishId, wish.SuggestionId).then(() => {
											let targetWishIndex = wishes.findIndex(thisWish => thisWish.WisherId === wish.WisherId || thisWish.SuggestionId === wish.SuggestionId)
											let newWishes = JSON.parse(JSON.stringify(wishes)) // Deep copy
											newWishes[targetWishIndex].GetterId = activeUserId
											setWishes(newWishes)
										})
									} else {
										toggleGetting(wish.WishId, wish.SuggestionId).then(() => {
											let targetWishIndex = wishes.findIndex(thisWish => thisWish.WisherId === wish.WisherId || thisWish.SuggestionId === wish.SuggestionId)
											let newWishes = JSON.parse(JSON.stringify(wishes)) // Deep copy
											newWishes[targetWishIndex].GetterId = null
											setWishes(newWishes)
										})
									}
								}}
								label={`${(wish.GetterId == null) ? ("Nobody is") : ((wish.GetterId === activeUserId) ? "You are" : wish.GetterId + " is")} getting ${wish.Title} for ${wish.WisherId || wish.RecommendeeId}`}
							/>
						</div>
						:
						<></>
				}
			</div>
		)
	}

	return (
		<>
			<Container>
				<Row className="TitleBar">
					<Col>
						<Button onClick={() => {
							navigate('../')
						}}>
							Home
						</Button>
					</Col>
					<Col>
						{params.get('id')}'s Wishlist
					</Col>
					<Col>
						<AmplifySignOut />
					</Col>
				</Row>
				<Row className="Section">
					<Col>
						<Button className="shadow-none" onClick={() => {
							setAddWish(true)
						}}>
							Add {(activeUserId === params.get('id')) ? 'Wish' : 'Suggestion'}
						</Button>
					</Col>
				</Row>
				<Row className="Section">
					<Col>
						{wishes.map(thisWish => <Wish wish={thisWish} />)}
					</Col>
				</Row>
			</Container>
			<Modal
				show={addWish}
				onHide={() => {
					setAddWish(false)
					setWishTitle("")
					setWishUrl("")
					setWishNote("")
				}}
			>
				<ModalHeader>
					<ModalTitle>
						Add a {(activeUserId === params.get('id')) ? 'Wish' : 'Suggestion'}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Form.Group>
						<Form.Label>{(activeUserId === params.get('id')) ? 'Wish' : 'Suggestion'} Title</Form.Label>
						<Form.Control placeholder="Red firetruck" onChange={e => setWishTitle(e.target.value)} />
					</Form.Group>
					<br />
					<Form.Group>
						<Form.Label>{(activeUserId === params.get('id')) ? 'Wish' : 'Suggestion'} URL</Form.Label>
						<Form.Control placeholder="https://www.amazon.com/s?k=red+firetruck" onChange={e => setWishUrl(e.target.value)} />
					</Form.Group>
					<br />
					<Form.Group>
						<Form.Label>{(activeUserId === params.get('id')) ? 'Wish' : 'Suggestion'} Note</Form.Label>
						<Form.Control as="textarea" placeholder="This firetruck must be elf-made and red. It doesn't need to be life sized." onChange={e => setWishNote(e.target.value)} />
					</Form.Group>
					<br />
					<Button className="shadow-none" onClick={submitAddWish}>
						Submit
					</Button>
				</ModalBody>
			</Modal>
			<Modal
				show={isDeleting}
				onHide={() => {
					setIsDeleting(false)
					setDeletionTarget(undefined)
					setDeletionTitle(undefined)
				}}
			>
				<ModalHeader>
					<ModalTitle>
						Delete This {(activeUserId === params.get('id')) ? 'Wish' : 'Suggestion'}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div>
						Are you sure you want to delete {deletionTitle}?
					</div>
					<div>
						This action cannot be undone
					</div>
					<br />
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
						<Button className="shadow-none" onClick={submitDelete}>
							Yes
						</Button>
						<Button className="shadow-none" onClick={cancelDelete}>
							No
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}

export default Groups;