import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';

import Amplify, { Auth } from 'aws-amplify';
Amplify.configure({
	Auth: {

		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		identityPoolId: 'us-east-2:a7b6ec26-bd71-4c74-b231-46ddce8adb54',

		// REQUIRED - Amazon Cognito Region
		region: 'us-east-2',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'us-east-2_b733hvPvb',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '43d9tr6a43kon2um1k5bk8vtci',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,

		// // OPTIONAL - Configuration for cookie storage
		// // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
		// cookieStorage: {
		// 	// REQUIRED - Cookie domain (only required if cookieStorage is provided)
		// 	domain: '.yourdomain.com',
		// 	// OPTIONAL - Cookie path
		// 	path: '/',
		// 	// OPTIONAL - Cookie expiration in days
		// 	expires: 365,
		// 	// OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
		// 	sameSite: "strict" | "lax",
		// 	// OPTIONAL - Cookie secure flag
		// 	// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
		// 	secure: true
		// },

		// // OPTIONAL - customized storage object
		// storage: MyStorage,

		// // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		// authenticationFlowType: 'USER_PASSWORD_AUTH',

		// // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		// clientMetadata: { myCustomKey: 'myCustomValue' },

		// // OPTIONAL - Hosted UI configuration
		// oauth: {
		// 	domain: 'your_cognito_domain',
		// 	scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
		// 	redirectSignIn: 'http://localhost:3000/',
		// 	redirectSignOut: 'http://localhost:3000/',
		// 	responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
		// }
	}
});


const rootElement = document.getElementById("root");
ReactDOM.render(
	<App />,
	rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
